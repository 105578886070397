<template>
  <div class="part-description-container">
    <div class="description-container">
      <div class="inner">
        <span class="part-id sku"> Part ID: </span>
        <span class="sku-1 sku">AutoZone: <span class="value">#{{sku}}</span> </span>
        <span class="sku-2 sku">BRExhaust: <span class="value">#{{partNumber}}</span> </span>
      </div>
      <div class="product-description">
        {{description}}
      </div>
      <div class="description-icons" v-if='false'>
        <div class="description-icon">
          <i class="fas icon-fa-tire"></i>
          Gasket Included
        </div>
        <div class="description-icon">
          <i class="fas icon-fa-volume-up"></i>
          Loud
        </div>
      </div>
    </div>
    <div class="features-section">
      <ExternalClientPdpButton :title="cmsExternalClientPdpButton" :url="clientURL" />
      <div class="features-icons">
        <div class="features-icon" v-for="featureIcon in data"
        :key="featureIcon.URI"
        >
          <img :src="featureIcon.URI" />
          {{featureIcon.Label}}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ExternalClientPdpButton from '@/components/buttons/ExternalClientPdpButton.vue'
import { useGetters } from '@/lib/helpers'

export default {
  props: { data: Array, description: String, partNumber: String, sku: String, clientURL: String },
  components: { ExternalClientPdpButton },
  setup () {
    const { cmsExternalClientPdpButton } = useGetters(['cmsExternalClientPdpButton'])
    return { cmsExternalClientPdpButton }
  }
}
</script>

<style lang='scss' scoped>
.part-description-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;
  .description-container {
    flex-grow: 1;
    max-width: 580px;
    .inner {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      width: 100%;
      border-top: $mf-border-color solid thin;
      border-bottom: $mf-border-color solid thin;
      span.sku {
        margin-right: 50px;
        font-weight: bold;
        .value{font-weight:normal;}
      }
    }
    .product-description {
      text-align: left;
      margin: 30px 0;
      line-height: 22px;
    }
    .description-icons {
      text-align: left;
      .description-icon {
        vertical-align: top;
        display: inline-block;
        text-align: center;
        max-width: 60px;
        height: 70px;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        i {
          font-size: 26px;
          margin-bottom: 6px;
        }
      }
    }
  }
  .features-section {
    width: 270px;
    min-width: 180px;
    .pdp-znet{
      display:block;
      margin-bottom:10px;
    }
    .z-net {
      margin-bottom: 15px;
      text-align: left;
    }
    .features-icon {
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-bottom: 8px;
      padding-left: 37px;
      text-align: left;
      line-height: 1em;
      @include mf-bold(13px);
      img {
        height:30px;
        width: 30px;
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 22px;
      }
    }
  }
}
</style>
